<template>
  <div class="navbar">
    <nut-navbar @on-click-back="backClick" title="收款记录"></nut-navbar>
  </div>
  <div>
    <div class="topbox">
      <nut-cell
        title="开始时间"
        :desc="startTime"
        @click="openStartTime"
      ></nut-cell>
      <nut-datepicker
        v-model="currentDate"
        @confirm="confirmStartTime"
        v-model:visible="showStartTime"
        :is-show-chinese="false"
      ></nut-datepicker>
      <nut-divider />
      <nut-cell
        title="结束时间"
        :desc="endTime"
        @click="openEndTime"
      ></nut-cell>
      <nut-datepicker
        v-model="currentDate"
        @confirm="confirmEndTime"
        v-model:visible="showEndTime"
        :is-show-chinese="false"
      ></nut-datepicker>
      <div style="text-align: center">
        <nut-button plain type="info" size="small" @click="handleSearch"
          >查询</nut-button
        >
      </div>
    </div>
    <div class="belowbox">
      <div class="periodTime">
        {{ startTime }} — {{ endTime }}
        <span style="color: red">(区间总计)</span>
      </div>
      <div class="totalLine">
        <span class="totalAmount"
          >+{{ Number(periodAmount.amount).toFixed(2) }}</span
        ><span class="periodTime">元</span>
      </div>
      <div class="periodLine">
        <span class="periodTime">总收入</span>
        <span class="count"
          >+{{ Number(periodAmount.amount).toFixed(2) }} 元
          <span class="splitLine">|</span>
          {{ Number(periodAmount.deals).toFixed(2) }} 笔</span
        >
      </div>
    </div>

    <div class="daily" id="scroll">
      <div class="load" v-if="isLoad">{{ loadText }}</div>
      <nut-infiniteloading
        containerId="scroll"
        :use-window="false"
        :has-more="hasMore"
        @load-more="loadMore"
        :load-icon="loadingIcon"
      >
        <div
          class="belowbox"
          v-for="(item, index) in dailyAmountList"
          :key="index"
        >
          <div class="periodTime">{{ item.startTime }}</div>
          <div class="totalLine">
            <span class="totalAmount">+{{ item.amount.toFixed(2) }}</span
            ><span class="periodTime">元</span>
          </div>
          <div class="periodLine">
            <span class="periodTime">总收入</span>
            <span class="count"
              >+{{ item.amount.toFixed(2) }}元 <span class="splitLine">|</span>
              {{ item.deals }} 笔</span
            >
          </div>
        </div>
      </nut-infiniteloading>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { ref, toRefs, reactive, onMounted } from 'vue'
import { reqPeriodAmount, reqDailyAmount } from '@/api'
import { PAGESIZE } from '@/constant'
import { Notify } from '@nutui/nutui'

const showStartTime = ref(false)
const showEndTime = ref(false)
const date = new Date()
const year = date.getFullYear()
const month = date.getMonth() + 1
const day = date.getDate()
const startTime = ref(year + '-' + month + '-' + day)
const endTime = ref(year + '-' + month + '-' + day)
const currentDate = new Date(year, month - 1, day)
const totalPages = ref('')
const initPage = ref('1')
const loadText = ref()
const isLoad = ref(true)
onMounted(async () => {
  await handleSearch()
})

// 搜索
const handleSearch = async () => {
  loadText.value = '加载中...'
  isLoad.value = true
  data.dailyAmountList.splice(0)
  hasMore.value = true
  initPage.value = 1
  data.periodAmount.amount = 0
  data.periodAmount.deals = 0
  try {
    await getPeriodAmount()
    await getDailyAmount()
  } catch (error) {
    Notify.danger(error, {
      duration: 3000
    })
  }
  loadText.value = data.dailyAmountList.length > 0 ? '加载中...' : '暂无数据'
}
const data = reactive({ periodAmount: {}, dailyAmountList: [] })
const { periodAmount } = toRefs(data)
const { dailyAmountList } = toRefs(data)

// 区间总计
const getPeriodAmount = async () => {
  const result = await reqPeriodAmount({
    startTime: Date.parse(startTime.value.replace(/(-)/g, '/')) / 1000,
    endTime: Date.parse(endTime.value.replace(/(-)/g, '/')) / 1000
  })
  data.periodAmount = result
}
// 每日总计
const getDailyAmount = async () => {
  const result = await reqDailyAmount({
    startTime: Date.parse(startTime.value.replace(/(-)/g, '/')) / 1000,
    endTime: Date.parse(endTime.value.replace(/(-)/g, '/')) / 1000,
    page: initPage.value,
    pageSize: PAGESIZE
  })
  for (const item of result.list) {
    data.dailyAmountList.push(item)
  }
  if (result.list.length > 0) {
    isLoad.value = false
  }
  totalPages.value = result.totalPages
  data.dailyAmountList.forEach((element) => {
    const dateTime = new Date(parseInt(element.date) * 1000)
    const year = dateTime.getFullYear()
    const month = dateTime.getMonth() + 1
    const day = dateTime.getDate()
    element.startTime = year + '-' + month + '-' + day
  })
}
// loadMore回调函数
const hasMore = ref(true)
const loadMore = async (done) => {
  setTimeout(() => {
    initPage.value++
    getDailyAmount()
    if (initPage.value >= totalPages.value) hasMore.value = false
    done()
  }, 500)
}

const openStartTime = () => {
  showStartTime.value = true
}
const openEndTime = () => {
  showEndTime.value = true
}
const confirmStartTime = (val) => {
  startTime.value = val.join('-')
}
const confirmEndTime = (val) => {
  endTime.value = val.join('-')
}

// 点击后退按钮
const router = useRouter()
const backClick = () => {
  router.push('/my')
}
</script>

<style lang="scss" scoped>
@import '@/style/viriables.scss';
@import '@/style/mixins.scss';
@import '@/style/customizedNutui.scss';

.nut-cell {
  margin: 0px 2%;
  width: 96%;
  box-shadow: 0px 0px 0px 0px;
  line-height: 5px;
  padding: 13px 8px;
}
:deep(.nut-cell) {
  .nut-cell__value {
    color: #666;
  }
}
.nut-button--small {
  padding: 0px 20px;
}
.nut-divider {
  width: 93%;
  color: #ccc;
  padding: 0px 10px;
}
.topbox {
  background-color: $bgColor;
  margin: 0.8rem 0.05rem 0rem 0.05rem;
  padding: 0.05rem 0.05rem;
  border-radius: 0.1rem;
}
.belowbox {
  background-color: $bgColor;
  margin: 0.1rem 0.05rem;
  border-radius: 0.1rem;
  padding: 0.1rem;
}
.navbar {
  position: fixed;
  top: 0rem;
  width: 100%;
}
.periodTime {
  font-size: 12px;
}
.totalLine {
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}
.periodLine {
  display: flex;
  justify-content: space-between;
}
.totalAmount {
  font-size: 20px;
  font-weight: bold;
  margin-right: 0.05rem;
}
.count {
  font-size: 12px;
}
.splitLine {
  padding: 0rem 0.1rem;
}
.daily {
  overflow-y: auto;
  position: absolute;
  left: 0;
  top: 3rem;
  bottom: 0rem;
  right: 0;
}
.load {
  text-align: center;
  margin-top: 0.1rem;
}
</style>
