<template>
  <div class="search">
    <div class="search__back iconfont" @click="handleBackClick">&#xe679;</div>
    <div class="search__content">
      <span class="search__content__icon iconfont" @click="handleSearchClick">
        &#xe741;
      </span>
      <input
        class="search__content__input"
        placeholder="输入商品名称"
        v-model="currentSearchText"
        @keyup.enter="handleSearchClick"
      />
    </div>
  </div>
  <div class="menuBack">
    <nut-menu active-color="#4fb0f9">
      <nut-menu-item
        v-model="filterGoods.selectStatus"
        @change="handleTabClick"
        :options="filterGoods.status"
      />
      <nut-menu-item
        v-model="filterGoods.selectOrder"
        @change="handleTabClick"
        :options="filterGoods.SortMethod"
      />
    </nut-menu>
  </div>
  <div class="product" id="scroll">
    <nut-infiniteloading
      containerId="scroll"
      :use-window="false"
      :has-more="hasMore"
      :load-icon="loadingIcon"
      @load-more="loadMore"
    >
      <div
        class="product__item"
        v-for="item in list"
        :key="item.id"
        @click="editGoodsClick(item.id)"
      >
        <img
          class="product__item__img"
          :src="item.goodsImg == null ? defaultPicURL : item.goodsImg"
        />
        <div class="product__item__detail">
          <h4 class="product__item__title">{{ item.goodsName }}</h4>
          <div class="product__item__info">
            <p class="product__item__price">
              <span class="product__item__yen">&yen;</span
              >{{ item.unitPrice.toFixed(2) }}
            </p>
            <p class="product__item__salesRatio">
              售卖比：
              {{ item.salesRatio }}
            </p>
          </div>
          <div
            class="edit"
            @click.stop="changeStatus(item.id, item.isActive, item)"
          >
            <nut-switch v-model="item.isActive" active-color="#1fa4fc" />
          </div>
          <div class="product__item__info">
            <p class="product__item__residue">库存: {{ item.residue }} 件</p>
            <p class="product__item__status">
              状态:
              {{ item.status }}
            </p>
          </div>
        </div>
      </div>
    </nut-infiniteloading>
  </div>

  <div class="bottom" @click="addGoodsClick(0)">
    <nut-button type="info" color="#4fb0f9" block>添加新商品</nut-button>
  </div>
</template>

<script setup>
import { reactive, ref, toRefs, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { PAGESIZE } from '@/constant'
import {
  reqGetGoodsList,
  getGoodsStatus,
  getGoodsOrderMethods,
  reqUpdateGoodsStatus
} from '@/api'

let pageNum = 1
const router = useRouter()
const data = reactive({ list: [] })
const { list } = toRefs(data)
const currentSearchText = ref('')
const defaultPicURL = '/images/defaultPic.png'
const loadingIcon = ref('/images/loading.png')

const filterGoods = reactive({
  status: [{ text: '全部商品', value: '' }],
  SortMethod: [],
  selectStatus: '',
  selectOrder: 'residue'
})

// 初始化页面
onMounted(async () => {
  await getProductList()
  await getStatusList()
  await getSortList()
})
// 返回
const handleBackClick = () => {
  router.push('/My')
}
// 搜索
const handleSearchClick = () => {
  pageNum = 1
  hasMore.value = true
  data.list.splice(0)
  getProductList()
}
const handleTabClick = () => {
  pageNum = 1
  hasMore.value = true
  data.list.splice(0)
  getProductList()
}
// 获取商品列表
const hasMore = ref(true)
const totalPage = ref(0)
const getProductList = async () => {
  const result = await reqGetGoodsList({
    goodsName: currentSearchText.value,
    page: pageNum,
    pageSize: PAGESIZE,
    orderBy: filterGoods.selectOrder,
    goodsStatus: filterGoods.selectStatus
  })
  totalPage.value = result.totalPages
  result.list.forEach((element) => {
    if (element.salesRatio === '') {
      element.salesRatio = '0%'
    }
    if (element.status === '销售中') {
      element.isActive = true
    } else {
      element.isActive = false
    }
  })
  for (const iterator of result.list) {
    data.list.push(iterator)
  }
  // 返回列表只有一页能正确显示
  if (totalPage.value === 1) {
    hasMore.value = false
  }
}

// 获取分页加载
const loadMore = (done) => {
  pageNum++
  setTimeout(() => {
    getProductList()
    if (pageNum >= totalPage.value) {
      hasMore.value = false
    }
    done()
  }, 500)
}
// 获取状态列表
const statusList = reactive({ list: [] })
const getStatusList = async () => {
  const statusResult = await getGoodsStatus({
    key: 'goodsStatus'
  })
  statusList.list = statusResult.list
  for (const item of statusList.list) {
    filterGoods.status.push({
      text: item.description,
      value: item.value
    })
  }
}
// 获取排序列表
const sortList = reactive({ list: [] })
const getSortList = async () => {
  const SortResult = await getGoodsOrderMethods({
    key: 'GoodsSort'
  })
  sortList.list = SortResult.list
  for (const item of sortList.list) {
    filterGoods.SortMethod.push({
      text: item.description,
      value: item.value
    })
  }
}
// 编辑商品点击事件
const editGoodsClick = (id) => {
  router.push(`/EditGoodsInfo/${id}`)
}

// 添加商品点击事件
const addGoodsClick = (id) => {
  router.push(`/EditGoodsInfo/${id}`)
}
// 更改商品状态
const changeStatus = async (id, isActive, item) => {
  const resStatus = ref('')
  if (isActive) {
    resStatus.value = 1
    item.status = '销售中'
  } else {
    resStatus.value = 2
    item.status = '下架'
  }

  await reqUpdateGoodsStatus({
    goodsId: id,
    status: Number(resStatus.value)
  })
}
</script>

<style lang="scss" scoped>
@import '@/style/viriables.scss';
@import '@/style/mixins.scss';
@import '@/style/customizedNutui.scss';

.search {
  display: flex;
  margin: 0.14rem 0 0.04rem 0;
  line-height: 0.32rem;
  &__back {
    width: 0.3rem;
    font-size: 0.24rem;
    color: #b6b6b6;
  }
  &__content {
    display: flex;
    flex: 1;
    background: $search-bgColor;
    border-radius: 0.16rem;
    &__icon {
      width: 0.44rem;
      text-align: center;
      color: $search-fontColor;
    }
    &__input {
      display: block;
      width: 100%;
      padding-right: 0.2rem;
      border: none;
      outline: none;
      background: none;
      height: 0.32rem;
      font-size: 0.14rem;
      color: $content-fontcolor;
      &::placeholder {
        color: $content-notice-fontcolor;
      }
    }
  }
}
.menuBack {
  margin-top: 0.1rem;
}
.product {
  overflow-y: auto;
  position: absolute;
  left: 0;
  top: 1rem;
  bottom: 0.5rem;
  right: 0;
  padding: 0rem 0.1rem;
  &__item {
    position: relative;
    display: flex;
    padding: 0.12rem 0;
    margin: 0 0.16rem;
    border-bottom: 0.01rem solid $content-bgColor;
    &__detail {
      overflow: hidden;
    }

    &__img {
      width: 0.68rem;
      height: 0.68rem;
      margin-right: 0.16rem;
      border-radius: 0.1rem;
    }
    &__title {
      margin: 0;
      line-height: 0.2rem;
      font-size: 0.14rem;
      color: $content-fontcolor;
      @include ellipsis;
    }

    &__info {
      display: flex;
      font-size: 0.12rem;
      color: $content-fontcolor;
    }
    &__status {
      margin: 0.08rem;
      margin-left: 0.1rem;
    }
    &__residue {
      margin: 0.08rem 0rem;
    }
    &__price {
      display: flex;
      margin: 0;
      line-height: 0.2rem;
      font-size: 0.14rem;
      color: $hightlight-fontColor;
      margin-top: 0.05rem;
    }
    &__salesRatio {
      margin: 0;
      line-height: 0.2rem;
      margin-top: 0.05rem;
      margin-left: 0.2rem;
      font-size: 0.12rem;
    }
    &__yen {
      font-size: 0.12rem;
    }
    &__origin {
      margin-left: 0.06rem;
      line-height: 0.2rem;
      font-size: 0.12rem;
      color: $light-fontColor;
      text-decoration: line-through;
    }
    .product__number {
      position: absolute;
      right: 0;
      bottom: 0.12rem;
      &__minus,
      &__plus {
        display: inline-block;
        width: 0.2rem;
        height: 0.2rem;
        line-height: 0.16rem;
        border-radius: 50%;
        font-size: 0.2rem;
        text-align: center;
      }
    }
  }
}
.edit {
  position: absolute;
  right: 0.05rem;
  top: 0.4rem;
}
.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 98%;
  background: #fcfcfc;
  padding: 7px 1%;
  flex-direction: row;
  display: flex;
  align-items: center;
}
</style>
