<template>
  <div class="wrapper">
    <div class="search">
      <div class="search__back iconfont" @click="handleBackClick">&#xe697;</div>
      <div class="search__content">
        <span class="search__content__icon iconfont" @click="handleSearchClick"
          >&#xe741;</span
        >
        <input
          class="search__content__input"
          placeholder="搜索全部订单"
          v-model="currentSearchText"
          @keyup.enter="handleSearchClick"
        />
      </div>
    </div>
    <div class="menuBack">
      <nut-menu active-color="#4fb0f9">
        <nut-menu-item
          v-model="filterOrders.selectStatus"
          @change="handleTabClick"
          :options="filterOrders.status"
        />
        <nut-menu-item
          v-model="filterOrders.selectOrder"
          @change="handleTabClick"
          :options="filterOrders.SortMethod"
        />
      </nut-menu>
    </div>
    <div class="orders" id="scroll">
      <nut-infiniteloading
        containerId="scroll"
        :use-window="false"
        :has-more="hasMore"
        :load-icon="loadingIcon"
        @load-more="loadMore"
      >
        <div
          class="order"
          v-for="(item, index) in list"
          :key="index"
          @click="toOrderInfoClick(item.orderNum)"
        >
          <div class="order__title">
            订单编号: {{ item.orderNum }} ({{ item.userName }})
            <span class="order__status">
              {{ item.status }}
            </span>
          </div>
          <div class="order__content">
            <div class="order__content__imgs">
              <template
                v-for="(innerItem, innerIndex) in item.products"
                :key="innerIndex"
              >
                <img
                  class="order__content__img"
                  :src="innerItem.goodsImg"
                  v-if="innerIndex <= 3"
                />
              </template>
            </div>
            <div class="order__content__info">
              <div class="order__content__price">
                ¥ {{ item.payAmount.toFixed(2) }}
              </div>
              <div class="order__content__count">
                共 {{ item.totalNumber }} 件
              </div>
            </div>
          </div>
        </div>
      </nut-infiniteloading>
    </div>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router'
import { reactive, toRefs, ref, onMounted } from 'vue'
import { PAGESIZE } from '@/constant'
import { reqSearchOrderList, getPayStatus, getOrderMethods } from '@/api'

const currentSearchText = ref('')
const hasMore = ref(true)
const data = reactive({ list: [] })
const loadingIcon = ref('/images/loading.png')
const filterOrders = reactive({
  status: [],
  SortMethod: [],
  selectStatus: '',
  selectOrder: 'create_time'
})
const statusList = reactive({ list: [] })

// 初始化页面
onMounted(async () => {
  await getOrderList()
  await getPayStatusList()
  await getSortList()
})
// 获取订单状态列表
const getPayStatusList = async () => {
  const statusResult = await getPayStatus({
    key: 'payStatus'
  })
  statusList.list = statusResult.list
  filterOrders.status.push({
    text: '全部',
    value: ''
  })
  for (const item of statusList.list) {
    filterOrders.status.push({
      text: item.description,
      value: item.value
    })
  }
}

// 获取订单排序列表
const sortList = reactive({ list: [] })
const getSortList = async () => {
  const SortResult = await getOrderMethods({
    key: 'OrdersSort'
  })
  sortList.list = SortResult.list
  for (const item of sortList.list) {
    filterOrders.SortMethod.push({
      text: item.description,
      value: item.value
    })
  }
}

// 获取订单信息
let pageNum = 1
const totalPage = ref(0)
const getOrderList = async () => {
  const result = await reqSearchOrderList({
    goodsName: currentSearchText.value,
    status: filterOrders.selectStatus,
    orderBy: filterOrders.selectOrder,
    page: pageNum,
    pageSize: PAGESIZE
  })
  totalPage.value = result.totalPages
  if (result) {
    result.orderList.forEach((order) => {
      const products = order.products || []
      const totalPrice = 0
      let totalNumber = 0
      data.list.push(order)
      products.forEach((productItem) => {
        totalNumber += productItem?.orderSales || 0
      })
      order.totalPrice = totalPrice
      order.totalNumber = totalNumber
    })
  }
  // 只返回一页时能正确显示
  if (totalPage.value === 1) {
    hasMore.value = false
  }
}

const { list } = toRefs(data)

// 订单分页加载
const loadMore = (done) => {
  pageNum++
  setTimeout(() => {
    getOrderList()
    if (pageNum >= totalPage.value) {
      hasMore.value = false
    }
    done()
  }, 500)
}

// 搜索全部订单
const handleSearchClick = () => {
  pageNum = 1
  hasMore.value = true
  data.list.splice(0)
  getOrderList()
}
// tab切换处理
const handleTabClick = () => {
  pageNum = 1
  hasMore.value = true
  data.list.splice(0)
  getOrderList()
}
// 查看订单详情
const toOrderInfoClick = (orderNum) => {
  router.push(`/adminOrderInfo/${orderNum}`)
}

// 返回
const router = useRouter()
const handleBackClick = () => {
  router.push('/')
}
</script>

<style lang="scss" scoped>
@import '@/style/viriables.scss';
@import '@/style/customizedNutui.scss';

.wrapper {
  padding: 0 0.18rem;
}
.search {
  display: flex;
  margin: 0.14rem 0 0.04rem 0;
  line-height: 0.32rem;
  &__back {
    width: 0.3rem;
    font-size: 0.24rem;
    color: #b6b6b6;
  }
  &__content {
    display: flex;
    flex: 1;
    background: $search-bgColor;
    border-radius: 0.16rem;
    &__icon {
      width: 0.44rem;
      text-align: center;
      color: $search-fontColor;
    }
    &__input {
      display: block;
      width: 100%;
      padding-right: 0.2rem;
      border: none;
      outline: none;
      background: none;
      height: 0.32rem;
      font-size: 0.14rem;
      color: $content-fontcolor;
      &::placeholder {
        color: $content-notice-fontcolor;
      }
    }
  }
}
.menuBack {
  margin-top: 0.1rem;
}
.orders {
  overflow-y: auto;
  position: absolute;
  left: 0;
  top: 1rem;
  bottom: 0.5rem;
  right: 0;
  padding: 0rem 0.1rem;
}
.order {
  position: relative;
  margin: 0 0.16rem;
  display: flex;
  flex-direction: column;
  padding: 0.12rem 0;
  border-bottom: 0.01rem solid $content-bgColor;
  &__title {
    margin: 0;
    line-height: 0.2rem;
    font-size: 0.14rem;
    color: $content-fontcolor;
  }
  &__status {
    float: right;
    font-size: 0.14rem;
    color: $light-fontColor;
  }
  &__content {
    display: flex;
    &__imgs {
      flex: 1;
    }
    &__img {
      width: 0.4rem;
      height: 0.4rem;
      margin-right: 0.16rem;
      margin-top: 0.12rem;
      border-radius: 0.1rem;
    }
    &__info {
      width: 0.7rem;
    }
    &__price {
      margin-bottom: 0.04rem;
      margin-top: 0.14rem;
      line-height: 0.2rem;
      font-size: 0.14rem;
      color: $hightlight-fontColor;
      text-align: right;
    }
    &__count {
      line-height: 0.14rem;
      font-size: 0.12rem;
      color: $content-fontcolor;
      text-align: right;
    }
  }
}
</style>
