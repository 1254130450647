<template>
  <div class="search">
    <div class="search__back iconfont" @click="handleBackClick">&#xe679;</div>
    <div class="search__content">
      <span class="search__content__icon iconfont" @click="handleSearchClick">
        &#xe741;
      </span>
      <input
        class="search__content__input"
        placeholder="输入用户名"
        v-model="currentSearchText"
        @keyup.enter="handleSearchClick"
      />
    </div>
  </div>
  <div class="menuBack">
    <nut-menu active-color="#4fb0f9">
      <nut-menu-item
        v-model="filterUsers.selectStatus"
        @change="handleSearchClick"
        :options="filterUsers.status"
      />
      <nut-menu-item
        v-model="filterUsers.selectRole"
        @change="handleSearchClick"
        :options="filterUsers.roles"
      />
    </nut-menu>
  </div>

  <div class="userInfoList" id="scroll">
    <nut-infiniteloading
      containerId="scroll"
      :use-window="false"
      :has-more="hasMore"
      @load-more="loadMore"
      :load-icon="loadingIcon"
    >
      <div
        v-for="item in userList.list"
        :key="item.id"
        @click="editUserInfo(item.id)"
      >
        <nut-cell :title="item.username">
          <template v-slot:link>
            <div @click.stop="changeStatus(item.id, item.isActive)">
              <nut-switch v-model="item.isActive" />
            </div>
          </template>
        </nut-cell>
      </div>
    </nut-infiniteloading>
  </div>
</template>

<script setup>
import {
  reqSearchUserList,
  reqAdminChangeUserStatus,
  reqAdminGetRoles,
  reqAdminGetUserStatus
} from '@/api'
import { PAGESIZE } from '@/constant'
import { onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const currentSearchText = ref('')

const filterUsers = reactive({
  status: [{ text: '全部状态', value: '' }],
  roles: [{ text: '全部角色', value: '' }],
  selectStatus: '',
  selectRole: ''
})

const loadingIcon = ref('/images/loading.png')

// 初始化页面
onMounted(async () => {
  await getRoles()
  await getStatusList()
  await getUserList()
})

// 获取用户列表
const userList = reactive({ list: [] })
const totalPages = ref('')
const initPage = ref('1')

// 获取用户列表
const getUserList = async () => {
  const result = await reqSearchUserList({
    username: currentSearchText.value,
    page: initPage.value,
    pageSize: PAGESIZE,
    userStatus: filterUsers.selectStatus,
    userRole: filterUsers.selectRole
  })
  result.userList.forEach((element) => {
    element.isActive = Boolean(element.status)
  })
  for (const item of result.userList) {
    userList.list.push(item)
  }
  totalPages.value = result.totalPages
}
// loadMore回调函数
const hasMore = ref(true)
const loadMore = async (done) => {
  setTimeout(() => {
    initPage.value++
    getUserList()
    if (initPage.value >= totalPages.value) hasMore.value = false
    done()
  }, 500)
}

// 进入用户账号详情页
const editUserInfo = async (id) => {
  router.push(`/EditAccountInfo/${id}`)
}
// 更改账号状态
const changeStatus = async (id, isActive) => {
  await reqAdminChangeUserStatus({
    userId: id,
    status: Number(isActive)
  })
}
// 返回
const handleBackClick = async () => {
  router.push('/My')
}
// 搜索和筛选
const handleSearchClick = async () => {
  userList.list.splice(0)
  hasMore.value = true
  initPage.value = 1
  await getUserList()
}

// 获取用户roleList
const roleList = reactive({ list: [] })
const getRoles = async () => {
  const rolesResult = await reqAdminGetRoles({
    status: 1
  })
  roleList.list = rolesResult
  for (const item of roleList.list) {
    filterUsers.roles.push({
      text: item.name,
      value: item.id
    })
  }
}

// 获取用户状态
const statusList = reactive({ list: [] })
const getStatusList = async () => {
  const StatusResult = await reqAdminGetUserStatus({
    key: 'userStatus'
  })
  statusList.list = StatusResult.list
  for (const item of statusList.list) {
    filterUsers.status.push({
      text: item.description,
      value: item.value
    })
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/viriables.scss';
@import '@/style/mixins.scss';
@import '@/style/customizedNutui.scss';

.userInfoList {
  overflow-y: auto;
  position: absolute;
  left: 0;
  top: 1rem;
  bottom: 0;
  right: 0;
  padding: 0rem 0.2rem;
}
.menuBack {
  margin-top: 0.1rem;
}
.bottom {
  position: fixed;
  bottom: 0;
  width: 90%;
  background: $bgColor;
  padding: 0.1rem 0rem;
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}
.search {
  display: flex;
  margin: 0.14rem 0 0.04rem 0;
  line-height: 0.32rem;
  &__back {
    width: 0.3rem;
    font-size: 0.24rem;
    color: #b6b6b6;
  }
  &__content {
    display: flex;
    flex: 1;
    background: $search-bgColor;
    border-radius: 0.16rem;
    &__icon {
      width: 0.44rem;
      text-align: center;
      color: $search-fontColor;
    }
    &__input {
      display: block;
      width: 100%;
      padding-right: 0.2rem;
      border: none;
      outline: none;
      background: none;
      height: 0.32rem;
      font-size: 0.14rem;
      color: $content-fontcolor;
      &::placeholder {
        color: $search-fontColor;
      }
    }
  }
}
</style>
