<template>
  <div class="navbar">
    <nut-navbar @on-click-back="backClick" title="更改用户信息"></nut-navbar>
  </div>
  <Form class="form" ref="target" v-slot="{ errors }">
    <div class="userInfo">
      <div class="userInfo__rows">
        <div class="userInfo__rows__key">
          用户名<span class="character">*</span>
        </div>
        <div>
          <Field
            class="userInfo__rows__value"
            name="username"
            :rules="checkUsername"
            v-model="userInfo.username"
          />
        </div>
      </div>
      <div class="error" v-if="errors.username">
        {{ errors.username }}
      </div>
      <div class="userInfo__rows">
        <div class="userInfo__rows__key">中文名</div>
        <div>
          <Field
            class="userInfo__rows__value"
            name="ChineseName"
            :rules="checkChineseName"
            v-model="userInfo.chineseName"
          />
        </div>
      </div>
      <div class="error" v-if="errors.ChineseName">
        {{ errors.ChineseName }}
      </div>
      <div class="userInfo__rows">
        <div class="userInfo__rows__key">英文名</div>
        <div>
          <Field
            class="userInfo__rows__value"
            name="EnglishName"
            :rules="checkEnglishName"
            v-model="userInfo.englishName"
          />
        </div>
      </div>
      <div class="error" v-if="errors.EnglishName">
        {{ errors.EnglishName }}
      </div>
      <div class="userInfo__rows">
        <div class="userInfo__rows__key">邮箱</div>
        <div>
          <Field
            class="userInfo__rows__value"
            name="userEmail"
            :rules="checkUserEmail"
            v-model="userInfo.email"
          />
        </div>
      </div>
      <div class="error" v-if="errors.userEmail">
        {{ errors.userEmail }}
      </div>
      <div class="userInfo__rows">
        <div class="userInfo__rows__key">微信</div>
        <div>
          <Field
            class="userInfo__rows__value"
            name="wechat"
            :rules="checkWechat"
            v-model="userInfo.wechat"
          />
        </div>
      </div>
      <div class="error" v-if="errors.wechat">
        {{ errors.wechat }}
      </div>
      <div class="userInfo__rows">
        <div class="userInfo__rows__key">角色</div>
        <div class="userInfo__rows__rolesCheckGroup">
          <nut-checkboxgroup v-model="userRoles.userRoles">
            <nut-checkbox
              :label="item.id"
              v-for="item of list"
              :key="item.id"
              class="userInfo__rows__roleCheckbox"
            >
              {{ item.name }}
            </nut-checkbox>
          </nut-checkboxgroup>
        </div>
      </div>
      <div class="userInfo__rows">
        <div class="userInfo__rows__key">账号状态</div>
        <div class="userInfo__rows__rolesCheckGroup">
          <nut-switch v-model="isActive" />
        </div>
      </div>

      <div class="userInfo__rows">
        <div class="userInfo__rows__key">新密码</div>
        <div>
          <Field
            class="userInfo__rows__value"
            name="newPwd"
            :rules="checkPassword"
            v-model="resetPassword"
            type="password"
          />
        </div>
      </div>
      <div class="error" v-if="errors.newPwd">
        {{ errors.newPwd }}
      </div>
      <div class="userInfo__rows">
        <div class="userInfo__rows__key"></div>
        <div>
          <nut-button
            type="info"
            color="#4fb0f9"
            block
            @click="resetPasswordClick"
            >重置密码</nut-button
          >
        </div>
      </div>
    </div>
  </Form>
  <div class="button">
    <div class="button__delete">
      <nut-button type="info" color="#ff0000" block @click="handleDeleteClick()"
        >删除用户</nut-button
      >
    </div>
    <div class="button__save">
      <nut-button type="info" color="#4fb0f9" block @click="handleSaveClick()"
        >保存</nut-button
      >
    </div>

    <nut-dialog
      :close-on-click-overlay="false"
      content="确认删除该用户？"
      v-model:visible="visibleDelete"
      @ok="handleDeleteOk"
    ></nut-dialog>
    <nut-dialog
      :close-on-click-overlay="false"
      content="确认保存？"
      v-model:visible="visible"
      @ok="handleOk"
    ></nut-dialog>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref, toRefs } from 'vue'
import { Notify } from '@nutui/nutui'
import { useRouter, useRoute } from 'vue-router'
import { Field, Form } from 'vee-validate'
import { useFormValidate } from '@/utils/validate'
import md5 from 'md5'
import {
  reqAdminUserInfo,
  reqAdminChangeUserInfo,
  reqAdminGetRoles,
  reqResetPassword,
  reqAdminDeleteUser
} from '@/api'

// 获取userId by route
const route = useRoute()
const userId = route.params.id

const resetPassword = ref('')
const target = ref(null)

// 表单校验方法
const {
  checkUsername,
  checkChineseName,
  checkEnglishName,
  checkUserEmail,
  checkWechat,
  checkPassword
} = useFormValidate()

// 页面初始化
onMounted(async () => {
  await getUserInfo()
  await getRoles()
})

// 获取用户信息
const data = reactive({ userInfo: {} })
const { userInfo } = toRefs(data)
const userRoles = reactive({ userRoles: [] })
const isActive = ref(false)
const getUserInfo = async () => {
  data.userInfo = await reqAdminUserInfo({ userId: userId })
  data.userInfo.roles.forEach((element) => {
    userRoles.userRoles.push(element.id)
  })
  if (data.userInfo.status === 1) {
    isActive.value = true
  }
}

// 保存账号信息
const changeUserInfo = async () => {
  data.userInfo.status = Number(isActive.value)
  data.userInfo.roles = userRoles.userRoles
  await reqAdminChangeUserInfo(data.userInfo)
}

// 删除用户
const deleteUser = async () => {
  await reqAdminDeleteUser({ userId: Number(userId) })
}

// 获取角色列表
const roleList = reactive({ list: [] })
const { list } = toRefs(roleList)

const getRoles = async () => {
  const result = await reqAdminGetRoles({
    status: 1
  })
  roleList.list = result
}

// 点击后退按钮
const router = useRouter()
const backClick = () => {
  router.push('/accountManage')
}
// 点击保存按钮
const handleSaveClick = async () => {
  const valid = await target.value.validate()
  if (valid.valid) {
    showDialog()
  }
}
// 对话框弹出
const visible = ref(false)
const showDialog = () => {
  visible.value = true
}
// 对话框ok回调
const handleOk = async () => {
  await changeUserInfo()
  Notify.success('操作成功', {
    duration: 1000,
    onClose() {
      router.push('/accountManage')
    }
  })
}

// 点击删除用户按钮
const handleDeleteClick = async () => {
  const valid = await target.value.validate()
  if (valid.valid) {
    showDeleteDialog()
  }
}
// 删除用户按钮的对话框弹出
const visibleDelete = ref(false)
const showDeleteDialog = () => {
  visibleDelete.value = true
}
// 删除用户按钮的对话框ok回调
const handleDeleteOk = async () => {
  try {
    await deleteUser()
    Notify.success('操作成功', {
      duration: 1000,
      onClose() {
        router.push('/accountManage')
      }
    })
  } catch (error) {
    Notify.danger(error, {
      duration: 3000
    })
  }
}
// 重置密码
const resetPasswordClick = async () => {
  const valid = await target.value.validate()
  if (valid.valid) {
    if (resetPassword.value === '') {
      Notify.warn('新密码不能为空', {
        duration: 3000
      })
      return
    }
    try {
      await reqResetPassword({
        userId,
        newPassword: md5(resetPassword.value)
      })

      Notify.success('重置成功', {
        duration: 1000
      })
    } catch (error) {
      Notify.danger(error, {
        duration: 3000
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/viriables.scss';
@import '@/style/customizedNutui.scss';

.navbar {
  position: fixed;
  top: 0rem;
  width: 100%;
}
.userInfo {
  margin-left: 0.25rem;
  margin-top: 0.9rem;
  &__rows {
    display: flex;
    align-items: center;
    margin-top: 0.15rem;
    &__key {
      font-weight: bold;
      font-size: 0.16rem;
      width: 25%;
    }
    &__value {
      display: block;
      width: 2.2rem;
      border: none;
      outline: none;
      background: $search-bgColor;
      height: 0.35rem;
      font-size: 0.14rem;
      border-radius: 0.1rem;
      margin-left: 0.05rem;
      padding-left: 0.1rem;
    }
    &__rolesCheckGroup {
      margin-left: 0.05rem;
    }
    &__roleCheckbox {
      margin-bottom: 0.05rem;
    }
  }
}
.button {
  position: absolute;
  margin-top: 0.4rem;
  width: 100%;
  background: $bgColor;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &__delete {
    width: 30%;
    margin-left: 13%;
  }
  &__save {
    width: 30%;
    margin-right: 13%;
  }
}
.error {
  margin-left: 0.95rem;
  color: $hightlight-fontColor;
}
.character {
  color: $hightlight-fontColor;
}
</style>
