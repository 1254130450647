<template>
  <div class="navbar">
    <nut-navbar @on-click-back="backClick" title="财务数据新增"></nut-navbar>
  </div>
  <Form class="form" ref="target" v-slot="{ errors }">
    <div class="financeInfo">
      <div class="financeInfo__rows">
        <div class="financeInfo__rows__key">
          类型<span class="character">*</span>
        </div>
        <div>
          <select v-model="financeData.type">
            <option
              v-for="item in typeList.list"
              :value="item.value"
              :key="item.value"
            >
              {{ item.description }}
            </option>
          </select>
        </div>
      </div>
      <div class="financeInfo__rows">
        <div class="financeInfo__rows__key">
          金额<span class="character">*</span>
        </div>
        <div>
          <Field
            name="amount"
            class="financeInfo__rows__value"
            :rules="checkNotNull"
            v-model="financeData.amount"
            :change="checkAmount(financeData.amount)"
          />
        </div>
      </div>
      <div class="error" v-if="errors.amount">
        {{ errors.amount }}
      </div>
      <div class="financeInfo__rows">
        <div class="financeInfo__rows__key">
          时间<span class="character">*</span>
        </div>
        <nut-cell
          class="dateChoose"
          :desc="currentTime"
          @click="openTime"
        ></nut-cell>
        <nut-datepicker
          v-model="currentDate"
          @confirm="confirmTime"
          v-model:visible="showTime"
          :is-show-chinese="false"
        ></nut-datepicker>
      </div>

      <div class="financeInfo__rows">
        <div class="financeInfo__rows__key">备注</div>
        <div>
          <textarea
            maxlength="50"
            class="financeInfo__rows__desc"
            v-model="financeData.description"
          />
        </div>
      </div>
    </div>
  </Form>
  <div class="bottom">
    <div class="bottom__save">
      <nut-button type="info" color="#4fb0f9" block @click="handleSaveClick()"
        >保存</nut-button
      >
    </div>
    <div class="bottom__continue">
      <nut-button
        type="info"
        color="#4fb0f9"
        block
        @click="handleContinueClick()"
        >继续添加</nut-button
      >
    </div>
  </div>
  <nut-dialog
    :close-on-click-overlay="false"
    content="确认保存？"
    v-model:visible="visible"
    @ok="handleOk"
  ></nut-dialog>
  <Docker :currentIndex="3" dockerName="Home" />
</template>
<script setup>
import { reactive, ref, toRefs, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import Docker from '@/components/Docker'
import { Field, Form } from 'vee-validate'
import { useFormValidate } from '@/utils/validate'
import { reqGetFinanceType, reqAddFinanceinfo } from '@/api'
import { Notify } from '@nutui/nutui'

const target = ref(null)
const showTime = ref(false)
const date = new Date()
const year = date.getFullYear()
const month = date.getMonth() + 1
const day = date.getDate()
const currentTime = ref(year + '-' + month + '-' + day)
const currentDate = new Date(year, month - 1, day)

const data = reactive({
  financeData: {}
})
const { financeData } = toRefs(data)

// 表单校验方法
const { checkNotNull } = useFormValidate()

// 初始化页面
onMounted(async () => {
  await getTypeList()
})
const checkAmount = (value) => {
  if (value !== undefined) data.financeData.amount = checkPrice(value)
}
const openTime = () => {
  showTime.value = true
}
const confirmTime = (val) => {
  currentTime.value = val.join('-')
}
// 处理金钱显示
const checkPrice = (value) => {
  let invalidValue = '' + value
  invalidValue = invalidValue
    .replace(/[^-\d.]/g, '') // 清除“数字”和“.”以外的字符
    .replace(/\.{2,}/g, '.') // 只保留第一个. 清除多余的
    .replace('.', '$#$')
    .replace(/\./g, '')
    .replace('$#$', '.')
    .replace(/^(-)*(\d+)\.(\d\d).*$/, '$1$2.$3') // 只能输入两个小数
  if (invalidValue.indexOf('-') < 0 && invalidValue !== '') {
    if (invalidValue.indexOf('.') < 0) {
      // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
      invalidValue = parseFloat(invalidValue)
    }
  }
  return invalidValue
}

// 获取财务数据类别
const typeList = reactive({ list: [] })
const getTypeList = async () => {
  let count = 0
  const typeResult = await reqGetFinanceType()
  for (const iterator of typeResult.list) {
    if (count !== 2) {
      typeList.list.push(iterator)
      count++
    }
  }
}
// 点击后退按钮
const router = useRouter()
const backClick = () => {
  router.push('/FinanceDataAnalysis')
}

// 新增财务数据信息
const addFinanceInfo = async () => {
  data.financeData.inputTime = currentTime.value
  try {
    await reqAddFinanceinfo(data.financeData)
    Notify.success('保存成功', {
      duration: 1000
    })
    router.push('/FinanceDataAnalysis/')
  } catch (error) {
    Notify.danger(error, {
      duration: 3000
    })
  }
}
// 对话框弹出
const visible = ref(false)
const showDialog = () => {
  visible.value = true
}
// 对话框ok回调
const handleOk = async () => {
  await addFinanceInfo()
}
// 点击保存按钮
const handleSaveClick = async () => {
  const valid = await target.value.validate()
  if (valid.valid) {
    showDialog()
  }
}
const handleContinueClick = async () => {
  location.reload()
}
</script>

<style lang="scss" scoped>
@import '@/style/viriables.scss';
@import '@/style/customizedNutui.scss';
.navbar {
  position: fixed;
  top: 0px;
  width: 100%;
}
.financeInfo {
  margin-left: 0.25rem;
  margin-top: 0.9rem;
  &__rows {
    display: flex;
    align-items: center;
    margin-top: 0.15rem;
    &__key {
      font-weight: bold;
      font-size: 0.16rem;
      width: 25%;
    }
    &__value {
      display: block;
      width: 2.2rem;
      border: none;
      outline: none;
      background: $search-bgColor;
      height: 0.35rem;
      font-size: 0.14rem;
      border-radius: 0.1rem;
      margin-left: 0.05rem;
      padding-left: 0.1rem;
    }
    &__desc {
      display: block;
      width: 210px;
      border: none;
      outline: none;
      background: $search-bgColor;
      height: 0.35rem;
      font-size: 0.14rem;
      border-radius: 0.1rem;
      margin-left: 0.05rem;
      height: 50px;
      padding: 10px;
    }
    &__stock {
      padding: 10px 20px 10px 0;
      margin-left: 0.1rem;
    }
  }
}
.dateChoose {
  align-items: center;
  display: block;
  width: 2.3rem;
  border: none;
  outline: none;
  background: $search-bgColor;
  height: 0.35rem;
  font-size: 0.14rem;
  border-radius: 0.1rem;
  margin-left: 0.05rem;
  padding-left: 0.1rem;
}
select {
  width: 230px;
  height: 0.37rem;
  font-size: 0.14rem;
  background: $search-bgColor;
  border-radius: 0.1rem;
  margin-left: 0.05rem;
  border: none;
}
.bottom {
  margin-top: 0.8rem;
  display: flex;
  justify-content: space-around;
  height: 0.39rem;
  border-top: 0.01rem;
  line-height: 0.39rem;
  &__save {
    width: 0.98rem;
    // background-color: $light-fontColor;
    color: $bgColor;
    text-align: center;
    font-size: 0.14rem;
  }
  &__continue {
    width: 0.98rem;
    text-align: center;
    font-size: 0.14rem;
    // background-color: $btn-bgColor;
    color: $bgColor;
  }
}
.error {
  font-size: 0.12rem;
  margin-left: 0.95rem;
  color: $hightlight-fontColor;
}
.character {
  color: $hightlight-fontColor;
}
</style>
