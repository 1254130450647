<template>
  <div class="wrapper">
    <ProductList />
  </div>
</template>

<script>
import ProductList from '@/views/goodsManage/ProductList'

export default {
  components: { ProductList },
  setup() {}
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 0 0.18rem;
}
</style>
