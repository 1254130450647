<template>
  <div class="wrapper">
    <div class="navbar">
      <nut-navbar @on-click-back="backClick" title="财务数据"></nut-navbar>
    </div>
    <div class="belowbox">
      <nut-cell
        :showIcon="true"
        title="选择日期区间"
        :desc="state.date ? `${state.date[0]}至${state.date[1]}` : '请选择'"
        @click="state.isVisible = true"
      >
      </nut-cell>
      <div class="types">
        <nut-button
          :plain="checkedTypes.list.indexOf(item.value) < 0"
          type="info"
          size="small"
          class="types__item"
          v-for="item in typeList.list"
          :key="item.value"
          color="#4fb0f9"
          @click="onTypeSelect(item.value)"
        >
          {{ item.description }}</nut-button
        >
      </div>
      <nut-button type="info" color="#4fb0f9" block @click="OnSearchClick"
        >查询</nut-button
      >
      <div class="daily" id="scroll">
        <div class="load" v-if="isLoad">{{ loadText }}</div>
        <nut-infiniteloading
          containerId="scroll"
          :use-window="false"
          :has-more="hasMore"
          @load-more="loadMore"
          :load-icon="loadingIcon"
        >
          <div class="belowbox" v-if="!isLoad">
            <nut-collapse
              v-model:active="activeName"
              :accordion="true"
              icon="down-arrow"
              v-for="(item, index) in state.financialList"
              :key="index"
            >
              <nut-collapse-item :name="index">
                <template v-slot:mTitle>
                  <div class="firstLine">
                    <div class="firstLine__item">{{ item.date }}</div>
                    <div class="firstLine__item">{{ item.type }}</div>
                    <div class="firstLine__item">
                      <span class="amount">{{ item.amount.toFixed(2) }}</span
                      ><span>元</span>
                    </div>
                  </div>
                </template>
                <span
                  v-if="item.description !== null && item.description !== ''"
                >
                  {{ item.description }}
                </span>
                <span
                  v-if="item.description === null || item.description === ''"
                >
                  {{ item.year }}年{{ item.month }}月{{ item.day }}日
                  {{ item.type }} {{ item.amount.toFixed(2) }}元
                </span>
              </nut-collapse-item>
              <div v-if="index !== state.financialList.length - 1">
                <nut-divider />
              </div>
            </nut-collapse>
          </div>
        </nut-infiniteloading>
      </div>
      <nut-popup
        position="bottom"
        :style="{ height: '20%' }"
        v-model:visible="state.showBottom"
      >
        <div class="title">财务报表</div>
        <div class="popup">
          <nut-table
            :columns="tableData.columns"
            :data="tableData.data"
            striped="true"
          ></nut-table>
        </div>
      </nut-popup>
    </div>
    <div class="bottom">
      <nut-button type="primary" plain color="#4fb0f9" @click="printReport"
        >导出</nut-button
      >
      <nut-button type="primary" plain color="#4fb0f9" @click="addFinanceData"
        >添加</nut-button
      >
      <nut-button
        type="primary"
        plain
        color="#4fb0f9"
        @click="state.showBottom = true"
        >展板</nut-button
      >
    </div>
  </div>
  <nut-calendar
    v-model:visible="state.isVisible"
    :default-value="state.date"
    type="range"
    :start-date="`2019-12-22`"
    @close="state.isVisible = false"
    @choose="setChooseValue"
    @select="select"
  >
  </nut-calendar>
  <nut-drag direction="y" :style="{ right: '0px', bottom: '240px' }">
    <nut-fixednav
      v-model:visible="state.showDrag"
      un-active-text="总计"
      active-text="总计"
      :overlay="false"
      v-if="!state.isVisible"
    >
      <template v-slot:list>
        <ul class="nut-fixednav__list">
          <li class="nut-fixednav__list-item">
            {{ Number(state.totalAmount.total).toFixed(2) }}
          </li>
        </ul>
      </template>
    </nut-fixednav>
  </nut-drag>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { ref, reactive, onMounted } from 'vue'
import { PAGESIZE } from '@/constant'
import { Notify } from '@nutui/nutui'
import {
  getFinanceDataType,
  reqGetFinanceRecord,
  reqGetfinancialReport,
  reqFinanceRecordDownload
} from '@/api'

const downloadName = ref('')
const downloadUrl = ref('')
const date = new Date()
const year = date.getFullYear()
const month = date.getMonth() + 1
const day = date.getDate()
const startTime = ref(year + '-' + month + '-' + '1')
const endTime = ref(year + '-' + month + '-' + day)
const loadText = ref()
const isLoad = ref(true)
const totalPages = ref('')
const initPage = ref('1')
const state = reactive({
  date: [startTime.value, endTime.value],
  isVisible: false,
  showBottom: false,
  showDrag: false,
  financialList: [],
  totalAmount: [{ total: 0 }]
})
const tableData = reactive({
  columns: [
    {
      title: '本',
      key: 'capital',
      align: 'center'
    },
    {
      title: '花销',
      key: 'expense',
      align: 'center'
    },
    {
      title: '结余',
      key: 'surplus',
      align: 'center'
    }
  ],
  data: [
    {
      capital: 0,
      expense: 0,
      surplus: 0
    }
  ]
})
const activeName = ref(-1)

// 初始化页面
onMounted(async () => {
  await getTypeList()
  await OnSearchClick()
  await getFinancialReport()
})

// 获取类型列表
const typeList = reactive({ list: [] })
const getTypeList = async () => {
  const typeResult = await getFinanceDataType({
    key: 'FinanceType'
  })
  typeList.list = typeResult.list
}

// 类型选择
const checkedTypes = reactive({ list: [] })
const onTypeSelect = (item) => {
  const index = checkedTypes.list.indexOf(item)
  if (index >= 0) {
    checkedTypes.list.splice(index, 1)
  } else {
    checkedTypes.list.unshift(item)
  }
}

// 搜索
const OnSearchClick = async () => {
  activeName.value = -1
  loadText.value = '加载中...'
  isLoad.value = true
  hasMore.value = true
  initPage.value = 1
  state.financialList.splice(0)
  await getFinanceRecord()
}

// 时间选择事件
const setChooseValue = (res) => {
  state.date = [res[0][3], res[1][3]]
}

// 获取财务数据
const getFinanceRecord = async () => {
  const result = await reqGetFinanceRecord({
    startTime: Date.parse(state.date[0].replace(/(-)/g, '/')) / 1000,
    endTime: Date.parse(state.date[1].replace(/(-)/g, '/')) / 1000,
    financeType: checkedTypes.list,
    page: initPage.value,
    pageSize: PAGESIZE
  })
  result.list.forEach((element) => {
    const dateTime = new Date(parseInt(element.date) * 1000)
    element.year = dateTime.getFullYear()
    element.month = dateTime.getMonth() + 1
    element.day = dateTime.getDate()
    element.date = element.year + '-' + element.month + '-' + element.day
    switch (element.type) {
      case 0:
        element.type = '本金'
        break
      case 1:
        element.type = '花销'
        break
      case 2:
        element.type = '罚金'
        break
      case 3:
        element.type = '销售额'
        break
    }
  })
  for (const item of result.list) {
    state.financialList.push(item)
  }
  if (result.list.length > 0) {
    isLoad.value = false
  }
  totalPages.value = result.totalPages
  state.totalAmount.total = result.totalAmount
  if (result.list.length > 0) {
    isLoad.value = false
  }
  loadText.value = state.financialList.length > 0 ? '加载中...' : '暂无数据'
}

// loadMore回调函数
const hasMore = ref(true)
const loadMore = async (done) => {
  setTimeout(() => {
    initPage.value++
    getFinanceRecord()
    if (initPage.value >= totalPages.value) hasMore.value = false
    done()
  }, 500)
}

// 获取财务报表数据
const getFinancialReport = async () => {
  const result = await reqGetfinancialReport()
  tableData.data[0].capital = result.capital.toFixed(2)
  tableData.data[0].expense = result.expense.toFixed(2)
  tableData.data[0].surplus = result.surplus.toFixed(2)
}
// 点击导出report
const printReport = async () => {
  try {
    const result = await reqFinanceRecordDownload({
      startTime: Date.parse(state.date[0].replace(/(-)/g, '/')) / 1000,
      endTime: Date.parse(state.date[1].replace(/(-)/g, '/')) / 1000,
      financeType: checkedTypes.list
    })
    downloadUrl.value = result.src
    downloadName.value = downloadUrl.value.toString().split('/').reverse()[0]
    const link = document.createElement('a')
    link.download = downloadName.value
    link.href = downloadUrl.value
    link.click()
    link.remove()
  } catch (error) {
    Notify.danger(error, {
      duration: 3000
    })
  }
}
// 点击新增财务数据
const addFinanceData = async () => {
  router.push('/FinanceDataAdd')
}
// 点击后退按钮
const router = useRouter()
const backClick = () => {
  router.push('/my')
}
</script>

<style lang="scss" scoped>
@import '@/style/viriables.scss';
@import '@/style/mixins.scss';
@import '@/style/customizedNutui.scss';
.nut-cell {
  margin: 0px 2%;
  width: 96%;
  box-shadow: 0px 0px 0px 0px;
  line-height: 5px;
  padding: 13px 8px;
}
:deep(.nut-cell) {
  .nut-cell__value {
    color: #666;
  }
}
:deep(.nut-collapse-item .collapse-item) {
  padding: 5px 5px;
  color: #000;
}
:deep(.nut-collapse-item .collapse-wrapper .collapse-content) {
  padding: 5px 5px;
}
:deep(.nut-icon:before) {
  color: $search-fontColor;
}
:deep(.nut-collapse-item .collapse-item:after) {
  border-bottom: none;
}
:deep(.nut-fixednav__btn) {
  background: linear-gradient(135deg, #1fa4fc 0%, #1fa4fc 100%);
  width: 60px;
  height: 50%;
}

:deep(.nut-fixednav__btn > .text) {
  padding-right: 5px;
}
.nut-fixednav__list {
  height: 50%;
  background: $content-bgColor;
}
.nut-button--primary {
  padding: 0 35px;
}
:deep(.nut-table__main--striped .nut-table__main__head__tr) {
  background-color: #4fb0f9;
}

.wrapper {
  overflow-y: auto;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0rem;
  right: 0;
  background: $content-bgColor;
}
.belowbox {
  background-color: $bgColor;
  margin: 0.1rem 0.05rem;
  border-radius: 0.1rem;
  padding: 0.1rem;
}
.daily {
  overflow-y: auto;
  position: absolute;
  left: 0;
  top: 2.3rem;
  bottom: 0.58rem;
  right: 0;
}
.types {
  display: flex;
  flex-wrap: wrap;
  padding: 0.1rem;
  margin-bottom: 0.05rem;
  &__item {
    margin-right: 0.05rem;
  }
}
.periodTime {
  font-size: 12px;
}
.desc {
  margin-top: 0.1rem;
}
.totalLine {
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}
.amount {
  font-weight: bold;
}
.totalAmount {
  font-size: 20px;
  font-weight: bold;
  margin-right: 0.05rem;
}
.bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 98%;
  background: #fcfcfc;
  padding: 10px 1%;
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
  margin-top: 1px;
}
.totalLine {
  position: fixed;
  bottom: 48px;
  left: 0;
  width: 98%;
  padding: 10px 10px;
  align-items: center;
  font-size: 14px;
  color: red;
}

.firstLine {
  font-size: 15px;
  flex-direction: row;
  display: flex;
  width: 300px;
  &__item {
    flex: 1;
  }
}
.popup {
  padding: 0.15rem 0.1rem;
}
.load {
  text-align: center;
  margin-top: 0.1rem;
}
.title {
  text-align: center;
  margin-top: 0.1rem;
  font-size: 14px;
}
</style>
